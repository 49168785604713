import React from 'react';
import PortfolioBlock from "./PortfolioBlock";
import Terminal from "./Terminal";
import Style from './Portfolio.module.scss';
import IconLink from "./IconLink";
import { Box } from "@mui/material";
import { info } from "../../info/Info";


export default function Portfolio() {
    const project = info.portfolio[0];
    const firstName = info.firstName.toLowerCase()

    function aboutMeText() {
        return <>
            <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cat
                abouthlasuj21 </p>
            <p><span style={{ color: info.baseColor }}>abouthlasuj21 <span
                className={Style.green}>(main)</span> $ </span>
                {info.hlasuj21}
            </p>
        </>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <PortfolioBlock image={project.image} />
            <Terminal text={aboutMeText()} />
            <Box className={'portfolio'} display={'flex'} flexDirection={'row'} gap={'0.5rem'}
                alignItems={'center'} fontSize={'1.5rem'}>
                <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                    <IconLink link={project.live} title={'Live Demo'} icon={'fa fa-safari'} />
                </Box>
                <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                    <IconLink link={project.source} title={'Resources'} icon={'fa fa-code'} />
                </Box>
            </Box>
        </Box>
    );
}
