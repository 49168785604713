import React, { useRef } from 'react';
import { Box } from "@mui/material";

export default function Toggler({ darkMode, handleClick }) {
   const transition = 'all 250ms ease';
   const audioRef = useRef(null);

   const handleToggleClick = () => {
      audioRef.current.play();
      handleClick();
   };

   return (
      <Box fontSize={'1.5rem'} sx={{ cursor: 'pointer', ":hover": { transform: 'translateY(-3px)', transition: transition } }}>
         {
            darkMode ?
               <span onClick={handleToggleClick} aria-label="Full Moon" role="img">🌕</span>
               :
               <span onClick={handleToggleClick} aria-label="New Moon" role="img">🌑</span>
         }

         <audio ref={audioRef}>
            <source src="toggleSound.mp3" type="audio/mp3" />
            Your browser does not support the audio element.
         </audio>
      </Box>
   );
}