import React from 'react';
import {Box} from "@mui/material";

function EmojiBullet(props) {
    const { emoji, text, email } = props;

    return (
        <Box component={'li'} fontSize={'1rem'} lineHeight={1.5} style={{ cursor: 'default' }}>
            <Box component={'span'} aria-label="emoji" role="img" 
                 mr={{ xs: '0.5rem', md: '1rem' }} fontSize={'1.5rem'}>
                {emoji}
            </Box> 
            {email ? 
                <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {text}
                </a> 
                : 
                text
            }
        </Box>
    );
}

export default EmojiBullet;