import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter } from "react-router-dom";

function App() {
   return (
      <div>
         <Analytics />
         <BrowserRouter>
            <BaseLayout />
         </BrowserRouter>
      </div>
   );
}


export default App;
