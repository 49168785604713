import selfie from "../img/selfie.png"
import hlasuj21 from "../img/hlasuj21.png"

export let colors = ["rgb(160, 107, 211)", "rgb(227, 97, 78)"];

export const info = {
    firstName: "David",
    lastName: "Bilnica",
    initials: "db",
    position: "a Blockchain Developer",
    selfPortrait: selfie,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, 
    baseColor: colors[0],
    miniBio: [
        {
            emoji: '☕',
            text: 'Fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'Based in the Czech Republic'
        },
        {
            emoji: "💼",
            text: "Freelancer ✈️🏖️"
        },
        {
            emoji: "📧",
            text: "hello@davidbilnica.cz",
            email: "hello@davidbilnica.cz"
        }
    ],
    socials: [
        {
            link: "https://www.facebook.com/david.bilnica",
            icon: 'fa fa-facebook',
            label: 'facebook'
        },
        {
            link: "https://www.instagram.com/david_bilnica/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/dbilnica",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/david-bilnica/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://twitter.com/DavidBilnica",
            icon: "fa fa-twitter",
            label: 'twitter'
        }
    ],
    bio: "Hello! I'm David. A passionate software engineer molded at Tomas Bata University in Zlín. While my heart beats in sync with codes and algorithms, I also savor a robust espresso and the calm of a sunset drive. If machines ever think of taking over, rest assured, I'll be firmly on the human side. Eager to join forces and make digital strides together?",
    skills:
        {
            proficientWith: [
                'blockchain', 
                'solidity', 
                'react', 
                'git', 
                'truffle',
                'hardhat',
                'ipfs', 
                'nodejs', 
                'smart-contracts', 
                'web3', 
                'ethereum-protocols',
                'frontend-development'
              ],              
              exposedTo: [
                'python', 
                'c#', 
                'c/c++', 
                'robot framework', 
                'selenium library',
                'dapp-development', 
                'docker',
                'rust'
              ]              
        }
    ,
    hobbies: [
        {
            label: 'music & guitar',
            emoji: '🎸'
        },
        {
            label: 'hiking',
            emoji: '🥾'
        },
        {
            label: 'travelling',
            emoji: '✈️'
        },
        {
            label: 'watching sunsets',
            emoji: '🌅'
        }
    ],
    portfolio: [
        {
            title: "Hlasuj21",
            live: "https://hlasuj21.space/",
            source: "https://digilib.k.utb.cz/handle/10563/51225",
            image: hlasuj21
        }
    ],
    hlasuj21: "In my Bachelor's thesis, I pioneered \"Hlasuj21\", a decentralized voting application leveraging the security and transparency of the Ethereum blockchain, employing the D21-Janeček method. My exploration covered Ethereum's tools and notable trends like NFTs and DeFi. This blockchain-driven approach to voting, paired with comprehensive testing, earned me a distinguished Red Diploma for academic excellence."
}